.demand-section {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background-color: #1c2023;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px 0px;
}

.demand-arriba {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
}

.demand-arriba-izq {
    width: 45%;
    padding: 20px;
}

.demand-arriba-izq img{
    width: 100%;
    object-fit: cover;
}

.demand-arriba-der {
    width: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
}

.demand-arriba-der h2 {
    font-size: 33px;
    font-weight: 600;
    color: #D6D6D6;
    margin: 0;
}

.demand-arriba-der h2 span{
    color: #F1772C;
}

.demand-arriba-der h3 {
    font-size: 20px;
    font-weight: 500;
    color: #B8B9BA;
    margin: 0;
    padding-top: 40px;
}

.demand-abajo {
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 30px 0px;
}

.demand-abajo-o {
    width: 30%;
    background-color: #2d2d2d;
    border-radius: 8px;
    text-align: center;
}

.demand-abajo-o h5 {
    font-size: 15px;
    font-weight: 600;
    color: #D6D6D6;
    padding: 15px 0px 6px 0px;
    margin: 0;
}

.demand-abajo-o h6 {
    font-size: 13px;
    font-weight: 500;
    color: #B8B9BA;
    padding: 0px 0px 15px 0px;
    margin: 0;
}

@media(max-width: 800px){
    .demand-section {
        width: 100%;
        max-width: 1600px;
        margin: auto;
        background-color: #1c2023;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 30px 0px;
    }
    
    .demand-arriba {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: auto;
    }
    
    .demand-arriba-izq {
        width: 45%;
        padding: 20px;
    }
    
    .demand-arriba-izq img{
        width: 100%;
        object-fit: cover;
    }
    
    .demand-arriba-der {
        width: 50%;
        padding: 20px;
    }
    
    .demand-arriba-der h2 {
        font-size: 20px;
        font-weight: 600;
        color: #D6D6D6;
        margin: 0;
    }
    
    .demand-arriba-der h3 {
        font-size: 18px;
        font-weight: 500;
        color: #B8B9BA;
        margin: 0;
        padding-top: 40px;
    }
    
    .demand-abajo {
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 30px 0px;
    }
    
    .demand-abajo-o {
        width: 30%;
        background-color: #2d2d2d;
        border-radius: 8px;
        text-align: center;
    }
    
    .demand-abajo-o h5 {
        font-size: 13px;
        font-weight: 600;
        color: #D6D6D6;
        padding: 15px 0px 6px 0px;
        margin: 0;
    }
    
    .demand-abajo-o h6 {
        font-size: 11px;
        font-weight: 500;
        color: #B8B9BA;
        padding: 0px 4px 15px 4px;
        margin: 0;
    }
    
}

@media(max-width: 500px){
    .demand-section {
        width: 100%;
        max-width: 1600px;
        margin: auto;
        background-color: #1c2023;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 30px 0px;
    }
    
    .demand-arriba {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: auto;
    }
    
    .demand-arriba-izq {
        width: 100%;
        padding: 0px;
    }
    
    .demand-arriba-izq img{
        width: 100%;
        object-fit: cover;
    }
    
    .demand-arriba-der {
        width: 100%;
        padding: 20px;
    }
    
    .demand-arriba-der h2 {
        font-size: 20px;
        font-weight: 600;
        color: #D6D6D6;
        margin: 0;
    }
    
    .demand-arriba-der h3 {
        font-size: 18px;
        font-weight: 500;
        color: #B8B9BA;
        margin: 0;
        padding-top: 10px;
    }
    
    .demand-abajo {
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 10px 0px;
    }
    
    .demand-abajo-o {
        width: 100%;
        background-color: #2d2d2d;
        border-radius: 8px;
        text-align: center;
        margin: 5px 0px;
    }
    
    .demand-abajo-o h5 {
        font-size: 13px;
        font-weight: 600;
        color: #D6D6D6;
        padding: 10px 0px 6px 0px;
        margin: 0;
    }
    
    .demand-abajo-o h6 {
        font-size: 11px;
        font-weight: 500;
        color: #B8B9BA;
        padding: 0px 4px 10px 4px;
        margin: 0;
    }
    
}