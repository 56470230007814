a.button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
    color: black;
}
#DIV_25 {
    padding: 25px;
}
#DIV_27 {
    height: 337.922px;
    position: relative;
    right: 0px;
    width: 260px;
    background: rgb(34, 34, 34) none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 12px;
    flex-flow: column nowrap;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow: hidden;
    padding: 32px;
}
#DIV_27:after {
    cursor: pointer;
    display: block;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_27:before {
    cursor: pointer;
    display: block;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_28 {
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 74px;
    justify-content: center;
    position: absolute;
    right: 142.547px;
    top: 0px;
    transform-origin: 58.7266px 37px;
    width: 117.453px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 12px 0px;
    flex: 0 0 auto;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 12px;
}

#DIV_28:after {
    cursor: pointer;
    display: block;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#DIV_28:before {
    cursor: pointer;
    display: block;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_30{
    caret-color: rgb(20 255 190);
    color: rgb(20 255 190 );
    column-rule-color: rgb(20 255 190);
    cursor: pointer;
    perspective-origin: 22.9531px 7px;
    text-align: center;
    text-decoration: none solid rgb(20 255 190);
    text-emphasis-color: rgb(20 255 190);
    text-transform: uppercase;
    transform-origin: 22.9531px 7px;
    border: 0px none rgb(20 255 190);
    font: 13px / 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    outline: rgb(20 255 190) none 0px;
}
#DIV_31{
    block-size: 36px;
    border-block-end-color: rgb(20 255 190);
    border-block-start-color: rgb(20 255 190);
    border-inline-end-color: rgb(20 255 190 );
    border-inline-start-color: rgb(20 255 190 );
    box-sizing: border-box;
    caret-color: rgb(20 255 190 );
    color: rgb(20 255 190 );
    column-rule-color: rgb(20 255 190 );
    cursor: pointer;
    height: 36px;
    inline-size: 45.9062px;
    perspective-origin: 22.9531px 18px;
    text-align: center;
    text-decoration: none solid rgb(20 255 190);
    text-emphasis-color: rgb(20 255 190);
    transform-origin: 22.9531px 18px;
    width: 45.9062px;
    border: 0px none rgb(20 255 190);
    font: 700 40px / 36px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    outline: rgb(20 255 190) none 0px;
}

#DIV_32 {
    align-items: center;
    block-size: 37px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 37px;
    inline-size: 39.5469px;
    justify-content: center;
    margin-block-start: -18px;
    margin-inline-start: 8px;
    min-width: auto;
    perspective-origin: 19.7656px 18.5px;
    transform-origin: 19.7734px 18.5px;
    width: 39.5469px;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: -18px 0px 0px 8px;
}
#DIV_32:after{
    cursor: pointer;
    display: block;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_32:before{
    cursor: pointer;
    display: block;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_33{
    cursor: pointer;
    height: 1px;
    position: relative;
    transform-origin: 2px 0.5px;
    width: 4px;
    background: rgb(20 255 190) none repeat scroll 0% 0% / auto padding-box border-box;
    margin: 0px 8px 0px 0px;
}
#DIV_33:after{
    cursor: pointer;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_33:before{
    cursor: pointer;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_34 {
    margin: 4px 0px 0px;
}
#DIV_34:after{
    cursor: pointer;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_34:before {
    cursor: pointer;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_35 {
    color: rgb(20 255 190);
    text-align: center;
    font: 9px / 9px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_36{
    color: rgb(20 255 190);
    column-rule-color: rgb(20 255 190);
    cursor: pointer;
    height: 24px;
    text-align: center;
    text-decoration: none solid rgb(20 255 190);
    text-emphasis-color: rgb(20 255 190);
    width: 27.5469px;
    font: 700 24px / 24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";   
}
#DIV_37 {
    height: 252.922px;
    width: 260px;
    z-index: 2;
    margin: -32px 0px 0px -32px;
}
#DIV_37:after {
    cursor: pointer;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#DIV_37:before {
    block-size: 252.922px;
    bottom: 0px;
    content: '""';
    cursor: pointer;
    display: block;
    height: 252.922px;
    inline-size: 260px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    perspective-origin: 130px 126.453px;
    position: absolute;
    right: 0px;
    top: 0px;
    transform-origin: 130px 126.461px;
    width: 260px;
    z-index: 2;
    background: rgba(0, 0, 0, 0) linear-gradient(to top, rgb(34, 34, 34) 20px, rgba(17, 17, 17, 0)) repeat scroll 0% 0% / auto padding-box border-box;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#IMG_38 {
    block-size: 248.922px;
    box-sizing: border-box;
    cursor: pointer;
    filter: '';
    height: 248.922px;
    inline-size: 260px;
    inset-block-start: 0px;
    inset-inline-start: 0px;
    left: 0px;
    object-fit: cover;
    perspective-origin: 130px 124.453px;
    top: 0px;
    transform-origin: 130px 124.461px;
    width: 260px;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: opacity 0.3s ease 0s;
}
#IMG_38:after {
    cursor: pointer;
}
#IMG_38:before {
    cursor: pointer;
}
#DIV_39 {
    position: relative;
    z-index: 3;
    margin: -64px 0px 0px;
    outline: rgb(255, 255, 255) none 0px;
}
#DIV_40 {
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 49px;
    inline-size: 196px;
    transform-origin: 98px 10.5px;
    width: 223px;
    border: 0px none rgb(255, 255, 255);
    font: 700 18px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 20px 0px 0px;
    line-height: 1.1;
}
#DIV_41{
    margin: 20px 0px;
}

#DIV_44 {
    align-items: center;
    display: inline-flex;
    height: 36px;
    inline-size: 196px;
    justify-content: center;
    text-transform: uppercase;
    transform-origin: 98px 18px;
    white-space: nowrap;
    width: 196px;
    background: rgb(20 255 190) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(17, 17, 17);
    border-radius: 4px;
    font: 13px / 13px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    outline: rgb(17, 17, 17) none 0px;
    overflow: hidden;
    padding: 9.75px 30.875px;
    transition: border-color 0.2s ease 0s, background-color 0.2s ease 0s, color 0.2s ease 0s;
}
#DIV_44 a{
    color: black;
    
}
#DIV_45 { 
    color: rgb(255, 255, 255);
    position: absolute;
    right: 16.3906px;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 4px 0px 0px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;

}
#place {
	/* position: absolute; */
	top: 0;
	right: 0;
	padding: 8px;
	color: white;
	font-weight: bold;
}

#bgplace {
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 37px;
    justify-content: center;
    position: absolute;
    right: 19.547px;
    top: 0px;
    transform-origin: 58.7266px 37px;
    width: 112px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 0px 0px 12px 12px;
    flex: 0 0 auto;
    font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 12px;
    -webkit-border-radius: 0px 0px 12px 12px;
    -moz-border-radius: 0px 0px 12px 12px;
    -ms-border-radius: 0px 0px 12px 12px;
    -o-border-radius: 0px 0px 12px 12px;
}