.PrivacyPolicy {
    width: 100%;
    max-width: 1600px;
    background-color: #141414;
    margin: auto;
    padding: 40px 0px;
}

.PrivacyPolicy .wrapper {
    width: 90%;
    margin: auto;
}

.PrivacyPolicy h1, .PrivacyPolicy p, .PrivacyPolicy h2, .PrivacyPolicy h3, .PrivacyPolicy li{
    color: white;
}

.PrivacyPolicy p span {
    font-weight: bold;
}