.workshop-section {
  width: 100%;
  max-width: 1600px;
  background-color: #333;
  margin: auto;
  padding: 60px 0px;
}

.workshop-arriba {
  width: 100%;
  margin: auto;
}

.workshop-arriba > .wrapper{
  padding: 20px 0px;
}

.workshop-arriba > .wrapper > div:first-child img{
  width: 52px;
  margin-left: -10px;
}

.workshop-arriba-first{
  text-align: center;
  padding: 0px 40px 0px;
  margin: auto;
}

.workshop-arriba h1 {
  font-size: 24px;
  color: #d3d3d3;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
}

.workshop-arriba h1 span {
  font-size: 24px;
  color: #00A87E;
  font-weight: 600;
  padding: 0px 0px 0px 0px;
}

.workshop-arriba h2 {
  font-size: 16px;
  color: #B8B9BA;
  font-weight: 300;
  margin-bottom: 25px;
}

.workshop-abajo-vid {
  width: 100%;
  background-color: #1c2023;
  display: flex;
  flex-direction: row;
  margin: 6px 0px;
  cursor: pointer;
}

.workshop-abajo-vid.big {
  width: 100%;
  margin: 0px;
  min-height: 75px;
}

.workshop-abajo-vid.big .workshop-abajo-vid-img img{
  margin-left: 30px;
  max-width: 40px;
}

.workshop-abajo-vid.big .workshop-abajo-vid-word h5{
  font-size: 15px;
}

.workshop-abajo-vid.big .workshop-abajo-vid-word h6{
  font-size: 20px;
  line-height: 1.1;
}

.workshop-abajo-vid.big .workshop-abajo-vid-der {
  width: 100%;
}

.workshop-abajo-vid-izq {
  width: 30%;
  margin: auto;
}

.workshop-abajo-vid-izq img{
  width: 100%;
  margin: auto;
}

.workshop-abajo-vid-der {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: auto;
  padding: 10px 10px 10px 0px;
}

.workshop-abajo-vid-img {

}

.workshop-abajo-vid-img img{
  max-width: 30px;
  margin-left: 15px;
}

.workshop-abajo-vid-word {
  margin-right: auto;
  text-align: left;
}

.workshop-abajo-vid-word h5 {
  font-size: 12px;
  font-weight: 500;
  color: #B8B9BA;
  padding: 5px 0px 0px 15px;
  margin: 0;
}

.workshop-abajo-vid-word h6 {
  font-size: 14px;
  font-weight: 600;
  color: #D6D6D6;
  padding: 0px 0px 0px 15px;
  margin: 0;
}

@media(max-width: 800px){
  .workshop-section {
      padding: 60px 0px;
  }
      
  .workshop-abajo-izq {
      width: 100%;
  }
  
  .workshop-abajo-vid-img {
  
  }
  
  .workshop-abajo-vid-img img{
      max-width: 50px;
      margin-left: 20px;
  }
  
  .workshop-abajo-vid-word {
      margin-right: auto;
      text-align: left;
  }
  
  .workshop-abajo-vid-word h5 {
      font-size: 15px;
      font-weight: 500;
      color: #B8B9BA;
      padding: 5px 0px 0px 15px;
      margin: 0;
  }
  
  .workshop-abajo-vid-word h6 {
      font-size: 20px;
      font-weight: 600;
      color: #D6D6D6;
      padding: 0px 0px 0px 15px;
      margin: 0;
  }
}

@media(max-width: 500px){
  
  .workshop-abajo-izq {
      width: 100%;
  }
  
  .workshop-abajo-vid-img {
  
  }
  
  .workshop-abajo-vid-img img{
      max-width: 25px;
      margin-left: 20px;
  }
  
  .workshop-abajo-vid-word {
      margin-right: auto;
      text-align: left;
  }
  
  .workshop-abajo-vid-word h5 {
      font-size: 11px;
      font-weight: 500;
      color: #B8B9BA;
      padding: 1px 0px 0px 15px;
      margin: 0;
  }
  
  .workshop-abajo-vid-word h6 {
      font-size: 14px;
      font-weight: 600;
      color: #D6D6D6;
      padding: 1px 0px 0px 15px;
      margin: 0;
  }
}

@media(min-width: 800px){

  .workshop-abajo-vid.big {
      min-height: initial;
      height: 75px;
  }
  
  .workshop-arriba h1 {
      font-size: 30px;
  }
  
  .workshop-arriba h1 span {
      font-size: 30px;
  }
  
  .workshop-arriba h2 {
      font-size: 20px;
  }
}

@media(min-width: 1024px){

  .workshop-arriba {
      width: 85%;
  }

  .workshop-arriba > .wrapper{
      display: flex;
      padding: 50px 0px;
  }

  .workshop-arriba-first{
      width: 60%;
      text-align: left;
      padding: 0px;
  }

  .workshop-arriba-second{
      width: 70%;
      padding: 20px;
  }

  .workshop-arriba h1 {
      font-size: 35px;
  }

  .workshop-arriba h1 span {
      font-size: 35px;
  }

  .workshop-arriba h2 {
      font-size: 22px;
  }

  .workshop-abajo-vid {
      flex-direction: column;
      width: 92%;
      margin: auto;
  }

  .workshop-abajo-vid-izq {
      width: 100%;
  }

  .workshop-abajo-vid-izq img{
      max-width: initial;
  }

  .workshop-abajo-der {
      width: 85%;
      margin: auto;
  }

  .workshop-abajo-vid-der {
      width: 100%;
      padding: 8px 5px 12px 0px;
      min-height: 70px;
  }

  .workshop-abajo-vid:not(.big) .workshop-abajo-vid-word h6 {
      line-height: 1.1;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
}

@media(min-width: 1200px){

  .workshop-arriba-first{
      padding: 0px;
  }

}
