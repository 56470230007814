[dir="rtl"] .carousel-container{
    direction: ltr;
  }
  button:focus {
    outline: none;
}

.instructores-section {
    width: 100%;
    max-width: 1600px;
    background-color: #141414;
    margin: auto;
    padding: 40px 0px;
}

.instructores-header {
    width: 90%;
    margin: auto;
}

.instructores-header h3 {
    font-size: 33px;
    color: #FCFCFC;
    font-weight: bold;
    padding: 30px 0px 0px 30px;
}

.instructores-header h4 {
    font-size: 26px;
    color: #B8B9BA;
    font-weight: 300;
    padding: 0px 0px 30px 30px;
}

.instructores-main {
    width: 90%;
    margin: auto;
}

.instructores-main-slides {
    width: 90%;
    height: 100%;
    margin: auto;
    cursor: pointer;
}

.instructores-photo {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.instructores-main-slides-o {
    width: 100%;
    height: 75%;
    background-color: #2d2d2d;
}

.instructores-main-slides-o img {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.instructores-text {
    background-color: #2d2d2d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 25%;
    width: 100%;
}

.instructores-text h1 {
    font-size: 11px;
    font-weight: 600;
    color: #D6D6D6;
    margin: 0px 3px 6px 6px;
    padding-top: 12px;
}

.instructores-text h2 {
    font-size: 9px;
    font-weight: 500;
    color: #B8B9BA;
    margin: 0px 3px 10px 6px;
    line-height: 1.1;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.instructores-footer {
    width: 100%;
    background-color: #1C2023;
    padding: 20px 0px;
}

.instructores-footer .wrapper{
    width: 90%;
    margin: auto;
    padding-left: 10px;
}

.instructores-footer-text {
    margin: 0px 0px 20px;
    text-align: left;
}

.instructores-footer-text h4{
    color: #00A87E;
    font-size: 20px;
    font-weight: 600;
}

.instructores-footer-text p{
    font-size: 16px;
    color: white;
}

@media(max-width: 800px){
    
.instructores-section {
    width: 100%;
    max-width: 1600px;
    background-color: #141414;
    margin: auto;
    padding: 40px 0px;
}

.instructores-header {
    width: 90%;
    margin: auto;
}

.instructores-header h3 {
    font-size: 27px;
    color: #FCFCFC;
    font-weight: bold;
    padding: 30px 0px 0px 30px;
}

.instructores-header h4 {
    font-size: 16px;
    color: #B8B9BA;
    padding: 0px 0px 30px 30px;
}

.instructores-main {
    width: 90%;
    margin: auto;
}

.instructores-main-slides {
    width: 90%;
    margin: auto;
}

.instructores-main-slides-o {
    width: 100%;
    background-color: #2d2d2d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.instructores-main-slides-o img {
    width: 100%;
    border-radius: 3px;
}
}

@media(max-width: 500px){
    .instructores-section {
        width: 100%;
        max-width: 1600px;
        background-color: #141414;
        margin: auto;
        padding: 20px 0px;
    }
    
    .instructores-header {
        width: 95%;
        margin: auto;
    }
    
    .instructores-header h3 {
        font-size: 22px;
        color: #FCFCFC;
        font-weight: bold;
        padding: 10px 0px 0px 10px;
    }
    
    .instructores-header h4 {
        font-size: 16px;
        color: #B8B9BA;
        padding: 0px 0px 10px 10px;
    }
    
    .instructores-main {
        width: 90%;
    }
    
    .instructores-main-slides {
        width: 95%;
        margin: auto;
    }
    
    .instructores-main-slides-o {
        width: 100%;
        background-color: #2d2d2d;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    
    .instructores-main-slides-o img {
        width: 100%;
        border-radius: 3px;
    }
}


@media(max-width: 400px){
    .instructores-section {
        width: 100%;
        max-width: 1600px;
        background-color: #141414;
        margin: auto;
        padding: 20px 0px;
    }
    
    .instructores-header {
        width: 95%;
        margin: auto;
    }
    
    .instructores-header h3 {
        font-size: 22px;
        color: #FCFCFC;
        font-weight: bold;
        padding: 10px 0px 0px 10px;
    }
    
    .instructores-header h4 {
        font-size: 16px;
        color: #B8B9BA;
        padding: 0px 0px 10px 10px;
    }
    
    .instructores-main {
        width: 99%;
    }
    
    .instructores-main-slides {
        width: 97%;
        margin: auto;
    }
    
    .instructores-main-slides-o {
        width: 100%;
        background-color: #2d2d2d;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    
    .instructores-main-slides-o img {
        width: 100%;
        border-radius: 3px;
    }
}

@media(min-width: 500px){
    .instructores-main-slides-o {
        height: 70%;
    }

    .instructores-text {
        height: 30%;
    }

    .instructores-text h1 {
        font-size: 15px;
        margin: 0px 5px 6px 10px;
        padding-top: 9px;
    }

    .instructores-text h2 {
        font-size: 13px;
        margin: 0px 5px 15px 10px;
    }
}

@media(min-width: 560px){
    .instructores-main-slides-o {
        height: 80%;
    }

    .instructores-text {
        height: 20%;
    }
}

@media(min-width: 750px){
    .instructores-text h1 {
        padding-top: 7px;
    }
}

@media(min-width: 900px){
    .instructores-main-slides-o {
        height: 81%;
    }

    .instructores-text {
        height: 19%;
    }

    .instructores-footer {
        padding: 25px 0px 25px 30px;
    }

    .instructores-footer .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .instructores-footer-text {
        margin: 0px;
    }

    .instructores-footer-text h4{
        font-size: 26px;
    }

    .instructores-footer-text p{
        font-size: 18px;
    }

    .instructores-footer-button a {
        font-size: 26px;
        padding: 12px 60px;
        line-height: 1.1;
    }
}

@media(min-width: 1200px){
    .instructores-main-slides-o {
        height: 80%;
    }

    .instructores-text {
        height: 20%;
    }

    .instructores-text h1 {
        font-size: 18px;
        margin: 0px 5px 5px 10px;
        padding-top: 12px;
    }

    .instructores-text h2 {
        font-size: 14px;
        margin: 0px 5px 15px 10px;
    }
}

@media(min-width: 1400px){
    .instructores-main-slides-o {
        height: 82%;
    }

    .instructores-text {
        height: 18%;
    }

    .instructores-text h1 {
        padding-top: 15px;
    }
}
