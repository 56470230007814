.footer{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background: #141414;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    font-family: 'Mulish', sans-serif;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
}   

.foot-main {
    width: 85%;
    margin: auto;
    font-family: 'Mulish', sans-serif;
}

.brand {
    width: 185px;
    height: auto;
    margin-left: 0px;
    cursor: pointer;
}

.footsquare1 {
    width: 100%;
}
.footsquare2 {
    width: 100%;
}

.footsquare2 ul {
    font-size: 14px;
    padding: 20px 0px 0px 0px;
}

.footsquare3 {
    width: 100%;
    padding: 20px 0px 0px 0px;
    text-align: left;
}

.footsquare3 ul{
    width: 100%;
    padding: 0px 0px 0px 0px;

}

.footsquare1 h4 {
    font-size: 26px;
    color: #D9E0E3;
    margin: 10px 0px 10px;
    font-weight: 600;
}

.footsquare1 h4 span {
    color: #00ab7b;
}

.footsquare2 ul a {
    font-size: 20px;
    list-style-type: none;
    color: #B8B9BA;
    margin: 10px 0;
    text-align: left;
    cursor: pointer;
    display: block;
    cursor: pointer;
    font-family: 'Mulish', sans-serif;
}

.footsquare3 h3{
    font-size: 20px;
    color: #B8B9BA;
    font-weight: 300;
    margin-bottom: 20px;
}

.footsquare3 ul a {
    font-size: 16px;
    list-style-type: none;
    color: #B8B9BA;
}
.social-icons__container {
    display: flex;
    justify-content: flex-start;
    padding-top: 9px;
    padding-bottom: 9px;
}

.social-icons__container a{
    margin: 0 !important;
}

.social-icons__container svg{
    font-size: 22px;
    margin-right: 20px;
}

.footer-country-flags__container{
    margin: 0;
    display: flex;
    justify-content: flex-end;
}

.footer-country-flags__container img{
    width: 16px;
    margin-left: 12px;
    cursor: pointer;
}

.bottom-footer {
    height: 85.5px;
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);   
    overflow: none;
}

.bottom-footer__container{
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;  
    color: rgba(255, 255, 255, 0.8);
}

.bottom-footer__container p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    margin: 0;
}

.bottom-footer__container a{
   color: rgba(255, 255, 255, 0.8);
   font-weight: bolder;
}


@media(max-width: 800px){

    .brand {
        margin-left: 0px;
    }

    .footsquare ul {
        margin: 0;
        margin-left: 50px;
        padding: 0;
    }
    
    .footsquare ul a {
        font-size: 12px;
        margin: 10px 0;
        text-align: left;
    }

    .footsquare ul a {
        margin-top: 18px;
    }

    .social-icons__container {
        justify-content: flex-start;
    }

    .footer-country-flags__container{
        justify-content: flex-start;
        padding-bottom: 45px;
    }

    .footer-country-flags__container img{
        width: 20px;
        margin-left: 0;
        margin-right: 16px;
    }
}

@media(max-width: 575px){
    
    .footsquare3 h3 {
        text-align: left;
    }

    .bottom-footer{
        height: 98.5px;
    }

    .bottom-footer__container p{
        font-size: 13px;
    }

}

@media(min-width: 800px){
    .bottom-footer {
        /*margin-bottom: 60px;*/
    }
}

@media(min-width: 900px){
    .footer {
        height: 280px;
    }

    .footsquare1 {
        width: 44%;
    }

    .footsquare2 {
        width: 14%;
    }

    .footsquare3 {
        width: 32%;
    }

    .foot-main {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}

@media(min-width: 1200px){
    .footsquare3 {
        width: 23%;
    }
}
