.certificates-section{
    width: 100%;
    max-width: 1600px;
    background-color: #141414;
    margin: auto;
    padding-bottom: 40px;
}

.certificates-header{
    width: 85%;
    margin: auto;
}

.certificates-header h2{
    font-size: 25px;
    color: #FCFCFC;
    font-weight: bold;
    padding: 30px 0px 0px 0px;
}

.certificates-header h3{
    font-size: 16px;
    color: #B8B9BA;
    font-weight: 300;
    padding: 0px 0px 10px 0px;
}

.certificates-main{
    width: 85%;
    margin: auto;
}

.certificates-card {
    margin: 20px 0;
    padding: 20px;
}

.certificates-card.orange h4{
    color: #F1772C;
}

.certificates-card.orange .btn{
    background-color: #F1772C;
    color: #1c2023;
}

.certificates-card.green h4{
    color: #00A87E;
}

.certificates-card.green .btn{
    background-color: #00A87E;
    color: #1c2023;
}

.certificates-card {
    background-color: #1c2023;
    border-radius: 10px;
}

.certificates-card > h4{
    font-size: 16px;
    font-weight: bold;
}

.certificates-card > p{
    font-size: 14px;
    color: #B8B9BA;
}

.certificates-icons {
    display: flex;
    text-align: center;
    margin: 10px 0;
}

.certificates-icons div{
    margin: 5px 30px 5px 5px;
    width: 75px;
}

.certificates-icons img{
    width: 62px;
}

.certificates-icons p{
    font-size: 16px;
    color: #B8B9BA;
    line-height: 1.2;
    margin-top: 5px;
}

.certificates-card .btn{
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 600;
    padding: 12px 20px;
}

@media(min-width: 500px) {

    .certificates-header h2{
        font-size: 33px;
    }

    .certificates-header h3{
        font-size: 26px;
        padding: 0px 0px 30px 0px;
    }

    .certificates-card > h4{
        font-size: 26px;
    }
    
    .certificates-card > p{
        font-size: 18px;
    }

}

@media(min-width: 900px) {

    .certificates-main .wrapper{
        display: flex;
    }

    .certificates-card {
        width: 44%;
        padding: 30px 60px 30px 30px;
        margin: 0px 40px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .certificates-icons {
        margin: 0px 0px 20px;
    }

    .certificates-icons div{
        margin: 5px 40px 5px 5px;
    }

    .certificates-card .btn{
        padding: 12px 25px;
    }

}