.modal-backdrop.show {
	opacity: 0.7;
}

.modal.show {
	display: flex !important;
	align-items: center;
	padding: 10px !important;
  z-index: 1000001 !important;
}

.modal.full .modal-dialog {
	max-width: 380px;
	width: 100%;
}

.modal-header .close {
  opacity: .8;
  font-size: 1.8rem;
}

.modal-body:after {
  content: '';
  position: absolute;
  top: 12px;
  left: 15px;
  width: 32px;
  height: 32px;
  background-image: url('../Assets/img/greenbook-k.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.modalx-izq .modalx-photo{
  width: 100%;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 200px; 
  background-repeat: no-repeat;
}

.modal-text2 .modalx-izq .modalx-photo{
  height: 430px; 
}

.modal.full .modalx-der {
	width: 100%;
}

.modal-text2 .modal-dialog {
	max-width: 340px;
	width: 100%;
}

.texto_modal {
  margin: auto;
  width: 77%;
  text-align: left;
}
.texto_modal2 {
  margin: auto;
  width: 77%;
  text-align: center;
}

.texto_modal2 h1 {
  font-weight: 800;
  font-size: 24px;
  margin: 1rem 0px 0px;
  line-height: 1;
}


.texto_modal h1 {
  font-weight: 800;
  font-size: 24px;
  margin: 1rem 0px 0px;
  line-height: 1;
}

.texto_modal h1 span{
  color: #df803c;
  font-size: inherit;
}

.texto_modal h2 {
  font-weight: 800;
  font-size: 26px;
  color: #df803c;
}

.texto_modal h3 {
  color: black;
  font-size: 12px;
  margin-bottom: 0px;
}

.madalx-fire {
  width: 77%;
  border-radius: 8px;
  background-color: rgba(0, 0, 255, 0.1);
  color: red;
  border: #141414;
  margin: auto;
  margin-bottom: 17px;
  margin-top: 17px;
  text-align: left;
  justify-content: center;
}

.madalx-fire h1{
  font-size: 12px;
  padding: 4px 0px 4px 0px;
  margin-bottom: 0;
}

.madalx-fire img{
  width: 14px;
  margin: 0px 6px 4px 10px;
}

.modalx-option {
  text-align: left;
  margin: 0px 0px 0px 25px;
  font-size: 12px;
}

.modalx_formulario {
  width: 77%;
  margin: auto;
  padding: 10px 0px 15px 0px;
}

.modalx_formulario .checkbox-group label{
  line-height: 1.1;
  font-weight: 500;
}

@media (min-width:730px) {

  .modalx-main{
    background-color: white;
  }

	.modalx-izq .modalx-photo{
	  height: 100%;
    background-position: 75% 50%;
    background-size: cover;
	}

  .modal-text2 .modalx-izq .modalx-photo {
    height: 100%;
  }
  
  .modal-text2 .modalx-izq{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    width: 55%;
  }

  .modal-text2 .modalx-der {
    width: 45%;
    background-color: #ffffffe6;
    border-color: white;
    margin-left: 55%; 
    z-index: 1;
  }

  .modal-text2 .modal-dialog {
    max-width: 720px;
  }
  
}

@media (min-width:1024px) {

	.texto_modal h1 {
	  font-size: 26px;
	  margin-bottom: 5px;
	}

	.texto_modal h2 {
	  font-size: 31px;
	  margin-bottom: 15px;
	}

	.texto_modal h3 {
	  font-size: 13px;
	  margin-bottom: 3px;
	}

	.madalx-fire h1{
    font-size: 15px;
    padding: 6px 0px 6px 0px;
	}

	.modalx_formulario {
	  padding: 15px 0px 30px 0px;
	}

  .modal-text2 .modal-dialog {
    max-width: 850px;
  }

  .modal-text2 .modalx-izq{
    width: 56%;
  }

  .modal-text2 .modalx-der {
    width: 44%;
    margin-left: 56%; 
  }
}

.modalx-photo2{
  width: 100%;
  background-position: center;
  background-size: contain;
  width: 100%;
   height: 260px; 
  background-repeat: no-repeat;
  background-color: #000000;
}