
.select-placeholder-text {
    color: pink;
    }
.contact__section{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    font-family: 'Mulish', sans-serif;
    background-color: #1C2023;
}

.contact__container{

}

.contact-header {
    width: 100%;
    color: whitesmoke;
    text-align: center;
    padding: 60px 30px;
}

.contact-header h2{
    font-size: 25px;
    color: whitesmoke;
    font-weight: bold;
}

.contact-header h2 span{
    color: #00A87E;
}

.contact-header h3{
    font-size: 16px;
    color: #B8B9BA;
    margin-top: 12px;
    font-weight: 300;
}

.contact-main {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    margin: auto;
}

.contact-main-column {
    width: 45%;
    padding-bottom: 60px;
}

.form-control {
    background-color: #2D2D2D;
    border-color: #2d2d2d ;
    padding: 30px 30px;
    border-radius: 8px;
    color: whitesmoke !important;
}

.form-control option{
    background-color: #2D2D2D;
    border-color: #2d2d2d ;
    color: whitesmoke  !important;
    padding: 30px 30px;
    border-radius: 8px;
}

.contact-opcion {
    color: #D9E0E3;
    margin-left: 22px;
    margin-top: 15px;
}


textarea.form-control { 
    padding-bottom: 60px;
}

.form-control:focus {
    color: #495057 !important;
}

.send-btn{
    padding: 9px 35px !important;
    font-size: 14px;
    text-transform: uppercase;
    background: #00AB7E;
    color: whitesmoke;
    font-family: 'Mulish', sans-serif;
    width: 50%;
    margin-left: 50%;
}

.send-btn:hover{
    background: #273b32;
    color: white;
}

.contact-loading-spinner__container{
    margin-bottom: 17px;
    margin-left: 27.1px;
}

.success-message-sent{
    color: #5d5d5d;
    height: 350px;
    width: 100%;
    max-width: 1600px;
    text-align: center;
}

.success-message-sent h2{
    font-weight: bolder;
    padding-top: 50px;
}

.success-message-sent h2, .success-message-sent h5{
    margin-bottom: 18px;
}

.modalx_renglones1 {
    width: 100%;
    margin: auto;
  }



  .modalx_innerrenglon1 {
    width: 100%;
    margin: auto;
    background-color: #2d2d2d;
    border-color: rgba(60, 60, 60, 0);
    color: whitesmoke !important;
    border: #141414;
    padding: 19px 25px;
    border-radius: 8px;
  }


@media(max-width: 500px){

    .contact__section{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        font-family: 'Mulish', sans-serif;
        background-color: #1C2023;
    }
    
    .contact__container{
        padding-bottom: 30px;
    }
    
    .contact-header {
        width: 100%;
        color: whitesmoke;
        text-align: center;
        padding: 40px 30px;
    }
    
    .contact-main {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 90%;
        margin: auto;
    }
    
    .contact-main-column {
        width: 100%;
        padding-bottom: 10px;
    }
    
    .form-control {
        background-color: #2D2D2D;
        border-color: #2d2d2d ;
        padding: 20px 30px;
        border-radius: 8px;
    }
    
    .form-control option{
        background-color: #2D2D2D;
        border-color: #2d2d2d ;
        color: whitesmoke;
        padding: 30px 30px;
        border-radius: 8px;
    }
    
    .contact-opcion {
        color: #D9E0E3;
        margin-left: 22px;
        font-size: 15px;
    }
    
    .form-control select{
        background-color: #2D2D2D;
        border-color: #2d2d2d ;
        color: whitesmoke;
        padding: 30px 30px;
        border-radius: 8px;
    }
    
    textarea.form-control { 
        padding-bottom: 60px;
    }
    
    .send-btn{
        padding: 9px 35px !important;
        font-size: 11px;
        text-transform: uppercase;
        background: #00AB7E;
        color: whitesmoke;
        font-family: 'Mulish', sans-serif;
        width: 100%;
        margin: auto;
    }
    
    .send-btn:hover{
        background: #273b32;
        color: white;
    }
    
    .contact-loading-spinner__container{
        margin-bottom: 17px;
        margin-left: 27.1px;
    }
    
    .success-message-sent{
        color: #5d5d5d;
    }
    
    .success-message-sent h2{
        font-weight: bolder;
    }
    
    .success-message-sent h2, .success-message-sent h5{
        margin-bottom: 18px;
    }
    
    .modalx_innerrenglon1 {
        width: 100%;
        margin: auto;
        background-color: #2d2d2d;
        border-color: rgba(60, 60, 60, 0);
        color: whitesmoke !important;
        border: #141414;
        padding: 10px 25px;
        border-radius: 8px;
      }
    
}

@media(min-width: 500px) {
    .contact-header h2{
        font-size: 33px;
    }

    .contact-header h3{
        font-size: 26px;
    }

}
