
.modal-image .modal-header .close {
  color: white;
}

.modal-image .modal-languages{
  display: none;
  position: absolute;
  top: -2px;
  right: 55px;
  z-index: 2;
}

.modal-image .modal-languages .language-options-modalx{
  width: initial;
  margin-top: 0px;
}

.modal-image .modal-dialog {
  max-width: 500px;
}

.modal-image .modal-body img {
  width: 100%;
}
.modal-image .modal-body::after {
  content: none;
}

@media (min-width:730px) {
  .modal-image .modal-languages{
    display: block;
  }
}