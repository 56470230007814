.partners__section{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background-color: #141414;
}

.partners__container {
    width: 85%;
    margin: auto;
    padding-top: 26px;
    padding-bottom: 20px;
}

.partners__container h2 {
    color: #FCFCFC;
    font-size: 25px;
    margin-bottom: 0px;
    font-weight: bolder;
    margin-bottom: 5px;
}

.partners__container h3 {
    color: #B8B9BA;
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: lighter;
}

.partners__logos {
    width: 100%;
    margin: auto;
}

.partners-bnoble{
    width: 100%;
    margin-bottom: 15px;
}

.partners-image{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.partners-image img{
    width: 100%;
}

@media(min-width: 500px){
    .partners__container h2 {
        font-size: 33px;
        margin-bottom: 10px;
    }

    .partners__container h3 {
        font-size: 26px;
    }
}

@media(min-width: 1024px) {

    .partners__logos {
        display: flex;
        align-items: center;
    }

    .partners-bnoble {
        margin-bottom: 0px;
        width: 250px;
        height: 100%;
    }

    .react-multi-carousel-list {
        width: 100%;
    }

    .partners-image{
        padding: 20px;
    }
}