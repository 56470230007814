.media-section {
  width: 100%;
  max-width: 1600px;
  background-color: #333;
  margin: auto;
  padding: 70px 0px 0px;
}

.media > .wrapper {
  width: 100%;
}

.media-header {
  width: 90%;
  margin: auto;
}

.media-header h3 {
  font-size: 27px;
  color: #FCFCFC;
  font-weight: bold;
  padding: 30px 0px 0px 30px;
}

.media-header h4 {
  font-size: 16px;
  color: #B8B9BA;
  font-weight: 300;
  padding: 0px 0px 30px 30px;
}

.media-main {
  width: 90%;
  margin: auto;
}

.media-item {
  padding: 10px;
}

.media-footer {
  width: 100%;
  background-color: #1C2023;
  padding: 20px 0px;
  margin-top: 20px;
}

.media-footer .wrapper{
  width: 90%;
  margin: auto;
  padding-left: 10px;
}

.media-footer-text {
  margin: 0px 0px 20px;
  text-align: left;
}

.media-footer-text h4{
  color: #00A87E;
  font-size: 20px;
  font-weight: 600;
}

.media-footer-text p{
  font-size: 16px;
  color: white;
}

@media(min-width: 900px){

  .media-footer {
    padding: 25px 0px 25px 30px;
  }

  .media-footer .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .media-footer-text {
    margin: 0px;
  }

  .media-footer-text h4{
    font-size: 26px;
  }

  .media-footer-text p{
    font-size: 18px;
  }

  .media-footer-button a {
    font-size: 26px;
    padding: 12px 60px;
    line-height: 1.1;
  }
}

@media(min-width: 1024px){
  .media-header h3 {
    font-size: 33px;
  }

  .media-header h4 {
    font-size: 26px;
  }

  .media-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .media-list2 {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .media-item {
    width: 50%;
    padding: 20px;
  }
}
