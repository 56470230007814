/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus { 
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
}
textarea:focus { 
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
}

.modal-body{
  text-align: center;
  padding: 0rem;
  justify-content: space-evenly;
}

.modalx-main {
    display: flex;
    flex-direction: column;
}
.modalx-main2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  font-size: 1.1rem;
}
.modalx-main3  {
  display: flex;
  background-color: white;
  font-size: 1.1rem;
}

.modalx-der {
  width: 100%;
  background-color: white;
  border-color: white;
}

.modal-content {
  background-color: transparent !important;
  border-color: transparent !important;
  width: 100%;
}

.early-x {
    margin: auto;
    width: 77%;
    margin-top: 20px;
}

.early-x h1{
    color: #599F80;
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
}

.early-x img{
    width: 26px;
    padding: 0px 4px 3px 0px;
}

.modal-header {
  border-bottom: 0px solid white;
  padding: 0px;
  position: relative;
  top: 30px;
  z-index: 2;
  padding-right: 15px;
}

.modal-content {
  border-radius: 8px;
}

.modal-world__container{
  width: 100px;
  margin: auto;
  margin-bottom: 25px;
}

.modal-world__container2{
  width: 190px;
  margin: auto;
  margin-bottom: 25px;
}

.naranjafuego{
  color: #df803c;
  font-size: 20px;
}

.modal-world__container img{
  width: 100%;
}

.modal-world__container2 img{
  width: 100%;
}

.contact-inline-form-modal {
  display: flex;
  flex-direction: column;
}

.responsive-input-modal {
  width: 75%;
  margin: auto;
  padding-top: 12px;
}

.inline-modal {
  border-radius: 8px;

}

#texto_modal_ {
  background-color: #141414 !important;
  padding: 23px 15px;
  color: whitesmoke !important;
}

.modal-languages-no-change{
  color: #df803c;
  margin-top: 15px;
  cursor: pointer;
}

.modal-languages-no-change:hover{
  text-decoration: underline;
}

.modal_btnx {
  margin-left: 0%;
  width: 100%;
  padding: 5px 10px !important;
  font-size: 18px;
  border-radius: 10px;
  text-transform: uppercase;
  background: #00AA80;
  color: white;
  font-family: 'Mulish', sans-serif;
  text-align: center  ;
  border-color: rgba(60, 60, 60, 0);
}

.contact_sections_modal {
  width: 50%;
  margin-left: 60px;
  margin-top: 20px;
}

.bottom-bar{
  height: 44px;
  background: #00A87E;
  width: 100%;
  max-width: 1600px;
  top: 656px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  transition: all .5s ease;
  position: fixed;
  bottom: 0;
  top: auto;
  border: 1px solid #00000024;
}

.bottom-bar.first-mobile{
  bottom: 44px;
  height: 40px;
}

.bottom-bar.second-mobile .bottom-bar__close{
  display: none;
}

.bottom-bar__container{
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bottom-bar__buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 300px;
}

.bottom-bar__buttons.first-desktop{
  display: none;
}

.bottom-bar__buttons .learn-more {
  text-decoration: none !important;
}

.bottom-bar-words {
  margin: 0px 5px;
}

.bottom-bar-words h1 {
  font-size: 13px;
  font-weight: bold;
  color: black;
  margin:0;
  line-height: 1;
}

.bottom-bar-words h1 span{
  font-size: 12px;
  font-weight: 400;
}

.bottom-bar-words h2 {
  font-size: 12px;
  font-weight: 500;
  color: black;
  margin:0;
  line-height: 1;
}

.bottom-bar__close{
  width: 20px;
  height: 28px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bottom-bar__close svg{
  color: #fff;
  font-size: 22px;
}

.bottom-bar__btn{
  font-size: 11px;
  min-width: 125px;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 6px 15px;
  cursor: pointer;
  transition: all .5s ease;
  text-align: center;
  line-height: 1.05;
}

.bottom-bar__button-1{
  color: #fff;
}

.bottom-bar__button-1:hover{
  background: #fff;
  color: rgb(55, 171, 134);
}

.bottom-bar__button-2{
  background: #1c1c1c;
  border-color: #1c1c1c;
  color: rgb(55, 171, 134);
  font-weight: 600;
  font-size: 10px;
  padding: 5px 3px;
}

.bottom-bar__button-2:hover{
  color: rgb(35, 109, 86);
  background: rgb(224, 224, 224);
  border: 1px solid rgb(224, 224, 224);
}

.bottom-bar__transformed{
  width: 180px;
  height: 150px;
  position: fixed;
  right: 0; 
  left: auto;
  transform: none;
  top: 100px;
  border-radius: 8px;
}

.bottom-bar__transformed .bottom-bar__container{
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.bottom-bar__transformed .bottom-bar__buttons{
  flex-direction: column;
  width: 125px;
  height: 105px;
  margin: auto;
  padding-top: 6px;
  align-items: space-between;
}

.bottom-bar__transformed .bottom-bar__btn{
  font-size: 12px;
  width: 115px;
}

.bottom-bar__transformed .bottom-bar__close{
  top: 1px;
  right: 2px;
}

.bottom-bar__transformed .bottom-bar__close svg{
  font-size: 14px;
}

.modalx_renglones {
  width: 100%;
  margin: auto;
  padding-bottom: 10px;
  border-radius: 8px;
}

.modalx_innerrenglon {
  width: 100%;
  margin: auto;
  padding: 8px 8px 8px 18px;
  border-radius: 8px ;
  background-color: #141414;
  border-color: rgba(60, 60, 60, 0);
  color: whitesmoke !important;
  border: #141414;
}

.modalx_btn_ {
  width: 100%;
  padding: 7px;
  border-radius: 8px;
  background-color: #00AA80;
  border-color: rgba(60, 60, 60, 0);
  color: whitesmoke;
  align-items: center;
}
.modalx_btn_3 {
  width: 18%;
  background-color: #dc3545;
  border-color: rgba(60, 60, 60, 0);
  color: whitesmoke;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 25px;
  margin-top: 17px;
  border-radius: 8px;
  
}

.modalx_btn_2 {
  width: 40%;
  padding: 6px;
  border-radius: 8px; 
  background-color: black;
  border-color: rgba(60, 60, 60, 0);
  color: white !important;
  align-items: center;
  position: absolute;
  top: 27%;
  left: 7%; 
  margin: auto;
  margin-bottom: 25px;
  font-size: 15px;
  z-index: 2;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: auto;
  pointer-events: none;
}

@media (min-width:500px) { 
  .modalx_btn_2 {
    width: 38%;
    padding: 8px;
    border-radius: 8px; 
    background-color: #dc0032;
    border-color: rgba(60, 60, 60, 0);
    color: rgb(255, 255, 255);
    align-items: center;
    bottom: 0;
    top: initial;
    left: 16%; 
    right: 0;
    margin: 0px 0px 25px;
    font-size: 15px;
  }
  .modal-content {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

@media (min-width:730px) { 
  .modalx-main {
    flex-direction: row;
  }

  .modalx-izq{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    width: 55%;
  }

  .modalx-der {
    width: 50%;
    background-color: #ffffffe6;
    border-color: white;
    margin-left: 50%; 
    z-index: 1;
  }

  .modal-dialog {
    max-width: 750px;
    width: 100%;
  }

}

.modalx_btn_main{
  margin-top: 15px;
  margin-left: 0%;
  margin-right: 40%;
  width: 100%;
  border-radius: 8px;
  background-color: #00AA80;
}

.modalx-option {
    text-align: left;
    margin: 10px 0px 0px 25px;
    font-size: 13px;
}
.language-options-modalx{
  width: 77%;
  margin: auto;
  display: flex;
  margin-top: 25px;
}
.language-options-modalx2{
  width: 34%;
  margin: auto;
  display: flex;
  margin-top: -8px;
  padding: 2px;
}

.language_modalx{
  color: #00ab7b;
  font-size: 16px;
  padding: 10px 6px;
  cursor: pointer;
  font-weight: bold;
}

.language_modalx.selected{
  color: #ef7b36;
}

#Facultyx{
  margin-top: 0.18rem;
}

@media(max-width: 800px){
  .bottom-bar{
    position: fixed;
    bottom: 0;
    width: 100%;
    top: auto;
    transition: none;
  }

  .bottom-bar__close svg{
    font-size: 18px;
  }

  .bottom-bar__transformed{
    width: 100%;
    position: fixed;
    right: auto; 
    top: auto;
    transform: none;
    height: 61.5px;
    border-radius: 0;
  }

  .bottom-bar__transformed .bottom-bar__container{
    flex-direction: row;
    height: 100%;
    align-items: center;
  }
  
  .bottom-bar__transformed .bottom-bar__buttons{
    flex-direction: row;
    width: 270px;
    height: 100%;
    margin: auto;
    align-items: center;
    padding-top: 0;
  }
  
  .bottom-bar__transformed .bottom-bar__btn{
    font-size: 12px;
    width: 130px;
  }
  
  .bottom-bar__transformed .bottom-bar__close{
    top: auto;
    right: 10px;
  }

  .bottom-bar__transformed .bottom-bar__close svg{
    font-size: 18px;
  }
}
  

@media (max-width:700px) {
  .contact_sections_modal {
    width: 50%;
    margin-left: 48px;
    margin-top: 15px;
  }
}

@media (max-width:500px) {

  .modalx-main2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: white;
    font-size: 1.1rem;
  }
  .language-options-modalx{
    width: 77%;
    margin: auto;
    display: flex;
    margin-top: 15px;
    font-size: 12px;
  }
  .language_modalx{

  }
  .language_modalx img{
    width: 15px;
    margin: 0px 18px 0px 3px;
    padding-bottom: 4px;
  }
    
  .label{
      margin-bottom: 0rem;
  }

  .modal-body{
    padding: 0rem ;
  }
  .modalx_innerrenglon {
    width: 100%;
    margin: auto;
    font-size: 15px;
    padding: 7px 7px 7px 20px;
  }
  .modalx_renglones {
      padding-bottom: 6px;
  }
  .modalx_btn_ {
    width: 100%;
    padding: 5px;
    border-radius: 9px;
    background-color: #00AA80;
    border-color: rgba(60, 60, 60, 0);
    color: whitesmoke;
    align-items: center;
    font-size: 15px;
  }
  
  .modalx_btn_main{
    margin-top: 0px;
    margin-left: 0%;
    margin-right: 40%;
    width: 100%;
    border-radius: 8px;
    background-color: #00AA80;
  }

  .modalx-main {
    display: flex;
    flex-direction: column;
  }
  .modalx-izq {
      width: 100%;
      object-fit: none;
  }

  .modalx-der {
    width: 100%;

  }

  .early-x {
    margin: auto;
    margin-top: 10px;
  }

  .early-x h1{
    color: #599F80;
    font-size: 18px;
    font-weight: bolder;
    text-align: left;
  }

  .early-x img{
    width: 26px;
    padding: 0px 4px 3px 0px;
  }
  .modalx_btn_3 {
    width: 18%;
    background-color: #dc3545;
    border-color: rgba(60, 60, 60, 0);
    color: whitesmoke;
    align-items: center;
    margin-left: 147px;
    margin-right: 4px;
    margin-bottom: 25px;
    margin-top: 5px;
    border-radius: 8px;
}
  
}
  
@media (max-width:400px) {
  .modalx_btn_3 {
    width: 18%;
    background-color: #dc3545;
    border-color: rgba(60, 60, 60, 0);
    color: whitesmoke;
    align-items: center;
    margin-left: 149px;
    margin-right: 4px;
    margin-bottom: 25px;
    margin-top: 5px;
    border-radius: 8px;
}

  .label{
      margin-bottom: 0rem;
  }

  .modal-body{
    padding: 0rem ;
  }
  .modalx_innerrenglon {
    width: 100%;
    margin: auto;
    padding: 7px 7px 7px 20px;
    font-size: 15px;
  }
  .modalx_renglones {
      padding-bottom: 6px;
  }
  .modalx_btn_ {
    width: 100%;
    padding: 5px;
    border-radius: 9px;
    background-color: #00AA80;
    border-color: rgba(60, 60, 60, 0);
    color: whitesmoke;
    align-items: center;
    font-size: 16px;
  }
  
  .modalx_btn_main{
    margin-top: 0px;
    margin-left: 0%;
    margin-right: 40%;
    width: 100%;
    border-radius: 8px;
    background-color: #00AA80;
  }
  .modalx-main {
    display: flex;
    flex-direction: column;
  }
  .modalx-izq {
      width: 100%;
  }
  
  .modalx-der {
    width: 100%;
  
  }
  
  .early-x {
    margin: auto;
    margin-top: 10px;
  }
  
  .early-x h1{
    color: #599F80;
    font-size: 18px;
    font-weight: bolder;
    text-align: left;
  }
  
  .early-x img{
    width: 26px;
    padding: 0px 4px 3px 0px;
  } 
  .language-options-modalx2{
    width: 34%;
    margin: auto;
    display: flex;
    margin-top: -13px;
    padding: 2px;
    margin-right: 102px;
    margin-bottom: -11px;
  } 
}



@media (min-width: 500px) {

  .modal-content {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .bottom-bar__buttons {
    width: 320px;
  }

  .bottom-bar__btn{
    min-width: 140px;
  }

  .bottom-bar__button-2 {
    font-size: 11px;
  }
  .modalx_btn_3 {
    width: 18%;
    background-color: #dc3545;
    border-color: rgba(60, 60, 60, 0);
    color: whitesmoke;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 13px;
    margin-top: 1px;
    border-radius: 8px;
    
  }

}

@media (min-width: 550px) {
  
  .bottom-bar__container{
    justify-content: center;
  }

  .bottom-bar__buttons {
    width: 365px;
  }
}

@media (min-width: 800px) {

  .bottom-bar__close{
    position: absolute;
    right: 5px;
    width: 28px;
  }

  .bottom-bar{
    height: 60px;
  }

  .bottom-bar.first-mobile{
    display: none;
  }

  .bottom-bar.second-mobile .bottom-bar__close{
    display: none;
  }

  .bottom-bar__container {
    justify-content: space-evenly;
  }

  .bottom-bar__buttons {
    width: 410px;
  }

  .bottom-bar__buttons.first-desktop{
    display: flex;
  }

  .bottom-bar-words {
    margin: 0px 15px;
  }

  .bottom-bar-words h1 {
    font-size: 15px;
  }

  .bottom-bar-words h1 span {
    font-size: 14px;
  }

  .bottom-bar-words h2 {
    font-size: 14px;
  }

  .bottom-bar__button-2{
    font-size: 12px;
    padding: 8px;
  }

  .bottom-bar__btn{
    font-size: 14px;
    min-width: 180px;
  }

  .bottom-bar__close{
    right: 20px;
  }

}

a:hover{
  color: #ffffff;
  text-decoration: underline;
}
