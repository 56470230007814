.about-section{
    width: 100%;
    max-width: 1600px;
    background-color: #141414;
    margin: auto;
    padding-bottom: 40px;
}

.about-header{
    width: 85%;
    margin: auto;
}

.about-header h1{
    font-size: 33px;
    color: #FCFCFC;
    font-weight: bold;
    padding: 30px 0px 0px 0px;
}

.about-header h2{
    font-size: 16px;
    color: #B8B9BA;
    font-weight: 300;
    padding: 0px 0px 10px 0px;
}

.about-header h2 span{
    font-size: 16px;
    font-weight: 600;
}

.about-header h2 span.green{
    color: #00A87E;
}

.about-main {
    width: 85%;
    margin: auto;
    background-color: #2d2d2d;
    display: flex;
    flex-direction: row;
}

.about-main-izq {
    width: 50%;
    object-fit: scale-down;
}

.about-main-izq-photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vw;
}

.about-main-der {
    width: 50%;
}

.about-main-inner-der {
    width: 70%;
    margin: auto;
}

.about-main-inner-der img{
    width: 70%;
    margin: auto;
    padding-top: 50px;
}

.about-main-inner-der p{
    width: 100%;
    margin: auto;
    padding-top: 40px;
    text-align: left;
    color: #B8B9BA;
    font-size: 12px;
    font-weight: 300;
    line-height: 23px;
}

.about-main-inner-der h6{
    width: 100%;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    color: #B8B9BA;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.about-boton {
    background-color: #2D2D2D;
    margin: auto ;
    width: 100%;
    color: #F1772C;
    border-color: #F1772C;
    border-radius: 8px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media(max-width: 800px){
    .about-section{
        width: 100%;
        max-width: 1600px;
        background-color: #141414;
        margin: auto;
        padding-bottom: 40px;
    }
    
    .about-header{
        width: 85%;
        margin: auto;
    }
    
    .about-header h1{
        font-size: 33px;
        color: #FCFCFC;
        font-weight: bold;
        padding: 30px 0px 0px 0px;
    }
        
    .about-main {
        width: 85%;
        margin: auto;
        background-color: #2d2d2d;
        display: flex;
        flex-direction: column;
    }
    
    .about-main-izq {
        width: 100%;
        object-fit: scale-down;
    }
    
    .about-main-der {
        width: 100%;
    }
    
    .about-main-inner-der {
        width: 80%;
        margin: auto;
    }
    
    .about-main-inner-der img{
        width: 60%;
        margin: auto;
        padding-top: 30px;
    }
        
    .about-main-inner-der h6{
        width: 100%;
        margin: auto;
        padding-top: 50px;
        padding-bottom: 20px;
        text-align: center;
        color: #B8B9BA;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

@media(max-width: 500px){
    .about-section{
        width: 100%;
        max-width: 1600px;
        background-color: #141414;
        margin: auto;
        padding-bottom: 40px;
    }
    
    .about-header{
        width: 85%;
        margin: auto;
    }
    
    .about-header h1{
        font-size: 25px;
        color: #FCFCFC;
        font-weight: bold;
        padding: 0px 0px 0px 0px;
        margin-bottom: 0px;
    }
        
    .about-main {
        width: 85%;
        margin: auto;
        background-color: #2d2d2d;
        display: flex;
        flex-direction: column;
    }
    
    .about-main-izq {
        width: 100%;
        object-fit: scale-down;
    }
        
    .about-main-der {
        width: 100%;
    }
    
    .about-main-inner-der {
        width: 80%;
        margin: auto;
    }
    
    .about-main-inner-der img{
        width: 60%;
        margin: auto;
        padding-top: 30px;
    }
    
    .about-main-inner-der h6{
        width: 100%;
        margin: auto;
        padding-top: 50px;
        padding-bottom: 20px;
        text-align: center;
        color: #B8B9BA;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

@media(min-width: 380px) {
    .about-header h2{
        font-size: 18px;
    }

    .about-header h2 span{
        font-size: 18px;
    }

    .about-main-inner-der p{
        font-size: 14px;
    }

    .about-boton {
        font-size: 14px;
        margin-top: 30px;
    }

}

@media(min-width: 800px) {
    .about-header h2{
        font-size: 26px;
        padding: 0px 0px 30px 0px;
    }

    .about-header h2 span{
        font-size: 26px;
    }

    .about-main {
        min-height: 650px;
    }
    .about-main-izq-photo {
        height: 100%;
    }

    .about-main-inner-der p{
        font-size: 18px;
    }

    .about-boton {
        margin-top: 90px;
    }
}