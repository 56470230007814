@-moz-keyframes modal-menu {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

@-webkit-keyframes modal-menu {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

@-o-keyframes modal-menu {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

@keyframes modal-menu {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

.link {
    font-weight: 600;
    padding: 12px 0 8px;
    cursor: pointer;
    display: block;
    opacity: 0.7;
    font-size: 18px;
    color: #d8dada;
}

.link:hover {
    color: #fff;
    opacity: 0.9;
}

.link img {
    width: 18px;
    margin-bottom: 14px;
}

.sublink {
    color: #d8dada;
    cursor: pointer;
    margin: 10px 0 10px 23px;
    display: block;
    font-size: 16px;
    line-height: 1.2;
}

.sublink.premium {
    color: #00A87E;
}

.sublink:hover {
    color: rgba(255,255,255,.9);
    text-decoration: none;
}

.nav-menu {
    animation: alternate;
    animation-duration: 0.5s;
    animation-name: modal-menu;
    background-color: #343a40;
    display: inherit;
    position: fixed;
    z-index: 1010;
    height: 100%;
    width: 78%;
    max-width: 340px;
    top: 0;
    overflow-y: scroll;
}

.nav-menu a {
    text-decoration: none;
}

.nav-menu-courses {
    margin-top: 15px;
}

.responsive-menu .navbar-logo-wrapper { 
    background-color: #343a40;
    align-items: center;
    position: absolute;
    height: 57.55px;
    display: flex;
    width: 100%;
    left: 0px;
    top: 0px;
    padding: .5rem 1rem;
}


.navbar-toggler svg {
    margin-left: 1rem;
}

.responsive-menu #menu {
    padding: 80px 25px 0px 35px;
}

.navbar-logo {
    width: 108px;
}

.responsive-menu #menu ul {
    margin-left: 25px;
}

.responsive-menu #menu li {
    margin-bottom: 5px;
}

.responsive-menu #header li {
    position: fixed;
    z-index: 1011;
}

.language {
    color: #00ab7b;
    padding: 3px 0px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
}

.language.selected{
    color: #ef7b36;
}
    
.language:hover {
    color: #ef7b36;
}

@media(min-width: 1024px) {

    .language {
        display: none;
    }

    .nav-menu-courses {
        margin-top: 0px;
    }

    .sublink {
        font-size: 17px;
    }
}