.navbar {
    justify-content: flex-start !important;
    max-width: 1600px;
    margin: auto;
    font-family: 'Mulish', sans-serif;
}

.navbar-brand-img img {
    width: 108px;
    cursor: pointer;
}

.navbar-brand span {
    margin-left: 0px;
}

.navbar-nav {
    text-align: left;
    padding: 7px;
}

.navbar-light {
    background-color: #1c1c1c !important;
}

.navbar-toggler {
    border: none !important;
}

.current-language__container {
    position: absolute;
    right: 22px;
    top: 20px;
    font-size: 14px;
    font-family: 'Mulish', sans-serif;
}

.current-language__container img {
    width: 18px;
    margin-bottom: 3px;
    margin-left: 1px;
}

.mobile-language__container {
    color: #B8B9BA !important;
    padding: 7px 2px;
    cursor: pointer;
}

.mobile-language__container img {
    width: 18px;
    margin-bottom: 2.5px;
    margin-left: 0px;
}

.language-options {
    display: none;
}

.nav-link {
    cursor: pointer;
    color: #B8B9BA !important;
}

@media(min-width: 992px) {
    .navbar-brand {
        margin-left: 8%;
    }
    .navbar-nav {
        width: 95%;
        justify-content: flex-end;
    }
    .nav-link {
        text-transform: none;
        cursor: pointer;
    }
    .last-nav-link {
        margin-right: 25px;
    }
    .current-language__container {
        display: none;
    }
    .mobile-language-options {
        display: none;
    }
    .language-options {
        display: flex;
        flex-direction: row;
    }
    .language__container {
        color: #00ab7b;
        font-size: 16px;
        padding: 10px 6px;
        cursor: pointer;
        font-weight: bold;
    }

    .language__container.selected{
        color: #ef7b36;
    }
    
    .navbar-expand-lg .navbar-nav .nav-link {   
        padding-right: 3rem;
    }
}
