body {
  margin: 0;
  font-family: "Mulish", sans-serif;
}

.slide-in-top-enter {
  transform: translateY(-100%);
}

.slide-in-top-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-top-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-top-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}

.btn-outline-green {
  color: #00AB7E;
  border-color: #00AB7E;
  padding: 12px 25px;
  border-radius: 8px;
}

.btn-outline-green:hover {
  background-color: #00AB7E;
  color: white;
}

.about-btn {
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  text-transform: none !important;
  background: #df803c !important;
  color: white;
  margin-top: 12px;
  border-radius: 8px;
  font-size: 18px !important;
  height: 48px !important;
  width: 160px !important;
  font-weight: bold;
}

@media(max-width: 768px) {
  .about-btn {
    font-size: 14px !important;
    height: 38px !important;
  }
}

@media(min: 1024px) {
  .btn-outline-green {
    padding: 12px 35px;
  }
}